import { ApolloClient, split, HttpLink, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';
import * as ws from 'isomorphic-ws';

import firebase from 'gatsby-plugin-firebase';

import { name, version } from '../../package.json';

import resolvers from './resolvers';

const loginFirebase = async (firebase) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInAnonymously()
      .then((anonUser) => {
        resolve(anonUser);
      })
      .catch((error) => {
        console.log('error', error);
        reject({ getIdToken: async () => '' });
      });
  });
};

/**
 * Apollo cache
 * @type {Object}
 */
const cache = new InMemoryCache();

/**
 * Apollo server link
 * @type {Object}
 */
const httpLink = new HttpLink({ uri: process.env.GATSBY_GRAPHQL_API_HOST, fetch });

const wsLink = new WebSocketLink({
  uri: process.env.GATSBY_GRAPHQL_WS_HOST,
  options: {
    reconnect: true,
    lazy: true,
  },
  webSocketImpl: ws,
});

wsLink.subscriptionClient.on('error', (e) => {
  console.log(e);
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;

const authLink = setContext(async (req, { headers }) => {
  const authUser = await loginFirebase(firebase);
  const newHeaders = { ...headers };
  const token = await authUser.user.getIdToken();

  if (token) newHeaders.authorization = `Bearer ${token}`;

  return {
    headers: newHeaders,
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

export default new ApolloClient({
  cache,
  link,
  resolvers,
  name,
  version,
});
