import { getUi } from '../queries/ui';

export default {
  Mutation: {
    updateUi: (parent, { input }, { cache }) => {
      const previous = cache.readQuery({ query: getUi });
      const newUi = { ...previous.getUi, ...input };

      cache.writeQuery({ query: getUi, data: { getUi: newUi } });

      return newUi;
    },
  },
  Query: {
    getUi: (parent, args, { cache }) => cache.data.getUi,
  },
};
